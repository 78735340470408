import React from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ICustomer } from '@features/customer/customer.interface';
import { PhonePattern } from '@utils/validation-rules';
import { useSaveCustomerForm } from './use-save-customer-form';
import { UserRoles } from '@features/user/user-role.type';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { useDisableRole } from '@features/auth/use-disable-role';
import { LanguagesOptions } from '../language.type';
import { EducationTypeOptions } from '../education-type.type';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ModeOfStudyOptions } from '../mode-of-study.type';

type Props = {
  customer?: ICustomer | void;
  ActionSlot?: React.ReactNode;
};

export const SaveCustomerForm: React.FC<Props> = ({ customer, ActionSlot }) => {
  const {
    submitHandler,
    isSaveEnabled,
    isLabelsLoading,
    buttonText,
    control,
    fundingBodyOptions,
    hepOptions,
    preferredCommunicationWaysOptions,
    customerLabelOptions,
  } = useSaveCustomerForm(customer);

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);
  const isDisabledDueAccountSuspension = customer?.isAccountSuspended;
  const isDisabled = isDisabledForAssessorAndQA || isDisabledDueAccountSuspension;

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
        {customer?.isAccountSuspended && (
          <Grid item xs={4} md={8} lg={12}>
            <Alert severity="warning">The account is suspended</Alert>
          </Grid>
        )}

        <Grid item xs={4} md={8} lg={12}>
          <Typography variant="h5">Customer Details</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="crn" label="Customer Reference Number" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="fundingBodyId"
            label="Funding Body"
            options={fundingBodyOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="customerLabelIds"
            label="Customer Labels"
            options={customerLabelOptions}
            isLoading={isLabelsLoading}
            multiple
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="firstName"
            label="First Name *"
            rules={{ required: 'First name is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="lastName"
            label="Last Name *"
            rules={{ required: 'Last name is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="preferredName" label="Preferred Name" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="email"
            label="Email *"
            rules={{ required: 'Email is required' }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="contactNumber"
            label="Telephone Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="altNumber"
            label="Alternative Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledToggleButton
            control={control}
            fullWidth
            name="extraMile"
            helperText="Explain what extra support the customer needs in the notes"
            getLabel={(value: boolean): string => (value ? 'Extra Mile' : 'Not Extra Mile')}
            disabled={isDisabledDueAccountSuspension}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="prefCommunicationWay"
            label="Preferred Communication Method"
            options={preferredCommunicationWaysOptions}
            isLoading={isLabelsLoading}
            multiple
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <ControlledTextField
            control={control}
            name="notes"
            label="Notes"
            multiline
            minRows={2}
            disabled={isDisabledDueAccountSuspension}
          />
        </Grid>

        <Grid item xs={4} md={8} lg={12}>
          <Typography variant="h5">Course Details</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="hepId"
            label="Higher Education Provider (HEP)"
            options={hepOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledDatePicker
            control={control}
            name="courseIndicativeSupportStartDate"
            label="Indicative Support Start Date"
            disablePast
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledDatePicker
            control={control}
            name="courseIndicativeSupportEndDate"
            label="Indicative Support End Date"
            disablePast
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="courseName" label="Course Name" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="courseDuration" label="Course Duration" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="courseYear" label="Course Year" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="educationType"
            label="Education Type"
            options={EducationTypeOptions}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="courseModeOfStudy"
            label="Mode of Study"
            options={ModeOfStudyOptions}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="academicYear" label="Academic Year" disabled={isDisabled} />
        </Grid>

        <Grid item xs={4} md={8} lg={12}>
          <Typography variant="h5">Consent to Pay</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledToggleButton
            control={control}
            fullWidth
            name="consentToPay"
            getLabel={(value: boolean): string => (value ? 'Consent to Pay' : 'No Consent to Pay')}
            disabled={isDisabled}
            helperText="Student Finance Wales only"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="preferredLanguage"
            label="Preferred Language"
            options={LanguagesOptions}
            multiple
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={4} md={8} lg={12}>
          <Typography variant="h5">Account Address</Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="address"
            label="Address"
            multiline
            minRows={2}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="addressLine2"
            label="Address Line 2"
            multiline
            minRows={2}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="addressLine3"
            label="Address Line 3"
            multiline
            minRows={2}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="city" label="City" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="country" label="Country" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="county" label="County" disabled={isDisabled} />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField control={control} name="postcode" label="Postcode" disabled={isDisabled} />
        </Grid>

        {customer?.isAccountSuspended && (
          <Grid item xs={4} md={8} lg={12}>
            <Alert severity="warning">The account is suspended</Alert>
          </Grid>
        )}
        <Grid item xs={4} md={8} lg={12}>
          <Stack direction="row" spacing={2}>
            {ActionSlot}
            <Button variant="contained" sx={{ flexGrow: 1 }} size="large" type="submit" disabled={!isSaveEnabled}>
              {buttonText}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
