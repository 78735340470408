import { IOrder } from '@features/order/order.interface';
import { Sort } from '@features/sorting/sort.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderSummary } from '../order-summary.interface';
import { ICustomer } from '@features/customer/customer.interface';

export type FormModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  customerLabelIds: ICustomer['customerLabelIds'][];
  customerFirstName: string;
  customerLastName: string;
  customerCrn: string;
  customerEmail: string;
  customerContactNumber: string;
  isCancelled: boolean | null;
  extraMile: boolean | null;
  daysInLastStatus: Sort | null;
};

export type ApiModel = {
  orderTypeId: IOrder['orderTypeId'][];
  orderStatusId: IOrder['orderStatusId'][];
  organisationId: IOrderSummary['organisationId'][];
  customerLabelIds: ICustomer['customerLabelIds'][];
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCrn: string | null;
  customerEmail: string | null;
  customerContactNumber: string | null;
  isCancelled: boolean | null;
  extraMile: boolean | null;
  daysInLastStatus: Sort | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  orderTypeId: { type: 'backend-label', optionKey: 'typeOptions', multiple: true, initial: [] },
  orderStatusId: { type: 'backend-label', optionKey: 'statusOptions', multiple: true, initial: [] },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  customerLabelIds: { type: 'backend-label', optionKey: 'customerLabelOptions', multiple: true, initial: [] },
  customerFirstName: { type: 'transportable', initial: '' },
  customerLastName: { type: 'transportable', initial: '' },
  customerCrn: { type: 'transportable', initial: '' },
  customerEmail: { type: 'transportable', initial: '' },
  customerContactNumber: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: null },
  extraMile: { type: 'transportable', initial: null },
  daysInLastStatus: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  orderTypeId: Serializers.backendValue,
  orderStatusId: Serializers.backendValue,
  organisationId: Serializers.backendValue,
  customerLabelIds: Serializers.backendValue,
  customerFirstName: Serializers.stringNullable,
  customerLastName: Serializers.stringNullable,
  customerCrn: Serializers.stringNullable,
  customerEmail: Serializers.stringNullable,
  customerContactNumber: Serializers.stringNullable,
  isCancelled: Serializers.booleanNullable,
  extraMile: Serializers.booleanNullable,
  daysInLastStatus: Serializers.enum,
});
