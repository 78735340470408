import { Moment } from 'moment-timezone';
import { ICustomer } from '@features/customer/customer.interface';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { LanguagesOptions } from '../language.type';
import { EducationTypeOptions } from '../education-type.type';

export type ApiModel = Pick<
  ICustomer,
  // Customer Details
  | 'firstName'
  | 'lastName'
  | 'preferredName'
  | 'crn'
  | 'fundingBodyId'
  | 'extraMile'
  | 'customerLabelIds'
  | 'notes'
  // Contact Details
  | 'email'
  | 'altNumber'
  | 'contactNumber'
  | 'address'
  | 'addressLine2'
  | 'addressLine3'
  | 'city'
  | 'country'
  | 'county'
  | 'postcode'
  | 'preferredLanguage'
  | 'prefCommunicationWay'
  // Course Details
  | 'courseDuration'
  | 'courseIndicativeSupportStartDate'
  | 'courseIndicativeSupportEndDate'
  | 'courseModeOfStudy'
  | 'courseName'
  | 'courseYear'
  | 'academicYear'
  | 'consentToPay'
  | 'educationType'
  | 'hepId'
>;

export type FormModel = Omit<ApiModel, 'courseIndicativeSupportStartDate' | 'courseIndicativeSupportEndDate'> & {
  courseIndicativeSupportStartDate: Moment | null;
  courseIndicativeSupportEndDate: Moment | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  // Customer Details
  firstName: { type: 'transportable', initial: '' },
  lastName: { type: 'transportable', initial: '' },
  preferredName: { type: 'transportable', initial: '' },
  crn: { type: 'transportable', initial: '' },
  fundingBodyId: { type: 'backend-label', optionKey: 'fundingBodyOptions', initial: null },
  extraMile: { type: 'transportable', initial: false },
  customerLabelIds: { type: 'backend-label', optionKey: 'customerLabelOptions', multiple: true, initial: [] },
  notes: { type: 'transportable', initial: '' },
  // Contact Details
  email: { type: 'transportable', initial: '' },
  altNumber: { type: 'transportable', initial: '' },
  contactNumber: { type: 'transportable', initial: '' },
  address: { type: 'transportable', initial: '' },
  addressLine2: { type: 'transportable', initial: '' },
  addressLine3: { type: 'transportable', initial: '' },
  city: { type: 'transportable', initial: '' },
  country: { type: 'transportable', initial: '' },
  county: { type: 'transportable', initial: '' },
  postcode: { type: 'transportable', initial: '' },
  preferredLanguage: {
    type: 'enum',
    options: LanguagesOptions,
    multiple: true,
    initial: [],
  },
  prefCommunicationWay: {
    type: 'backend-label',
    optionKey: 'preferredCommunicationWaysOptions',
    multiple: true,
    initial: [],
  },
  // Course Details
  courseDuration: { type: 'transportable', initial: null },
  courseIndicativeSupportStartDate: { type: 'date', initial: null },
  courseIndicativeSupportEndDate: { type: 'date', initial: null },
  courseModeOfStudy: { type: 'transportable', initial: '' },
  courseName: { type: 'transportable', initial: '' },
  courseYear: { type: 'transportable', initial: null },
  academicYear: { type: 'transportable', initial: null },
  consentToPay: { type: 'transportable', initial: false },
  educationType: { type: 'enum', options: EducationTypeOptions, initial: null },
  hepId: { type: 'backend-label', optionKey: 'hepOptions', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  // Customer Details
  firstName: Serializers.string,
  lastName: Serializers.string,
  preferredName: Serializers.string,
  crn: Serializers.stringNullable,
  fundingBodyId: Serializers.backendValue,
  extraMile: Serializers.boolean,
  customerLabelIds: Serializers.array,
  notes: Serializers.stringNullable,
  // Contact Details
  email: Serializers.string,
  altNumber: Serializers.string,
  contactNumber: Serializers.stringNullable,
  address: Serializers.string,
  addressLine2: Serializers.string,
  addressLine3: Serializers.string,
  city: Serializers.string,
  country: Serializers.string,
  county: Serializers.string,
  postcode: Serializers.string,
  preferredLanguage: Serializers.array,
  prefCommunicationWay: Serializers.array,
  // Course Details
  courseDuration: Serializers.integerNullable,
  courseIndicativeSupportStartDate: Serializers.date,
  courseIndicativeSupportEndDate: Serializers.date,
  courseModeOfStudy: Serializers.string,
  courseName: Serializers.string,
  courseYear: Serializers.integerNullable,
  academicYear: Serializers.integerNullable,
  consentToPay: Serializers.boolean,
  educationType: Serializers.enum,
  hepId: Serializers.backendValue,
});
